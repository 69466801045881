import i18n from '@/i18n/i18n'

import type { KpiCriteria, Target } from '@/domain/model/target/types'

const TargetEnum = {
  ALL: 'all',
  MEDIA: 'media',
  CATEGORY: 'category'
} as const

export const TargetLabel = {
  [TargetEnum.ALL]: i18n.global.t('target.target_select.all'),
  [TargetEnum.MEDIA]: i18n.global.t('target.target_select.media'),
  [TargetEnum.CATEGORY]: i18n.global.t('target.target_select.category')
} as const satisfies Record<Target, string>

const KpiCriteriaEnum = {
  CPA: 'cpa',
  ROAS: 'roas',
  CPA_ONLY: 'cpa_only',
  ROAS_ONLY: 'roas_only',
  CV_ONLY: 'cv_only'
} as const

export const KpiCriteriaLabel = {
  [KpiCriteriaEnum.CPA]: i18n.global.t('target.kpi_index_setting.criteria_cpa'),
  [KpiCriteriaEnum.ROAS]: i18n.global.t('target.kpi_index_setting.criteria_roas'),
  [KpiCriteriaEnum.CPA_ONLY]: i18n.global.t('target.kpi_index_setting.criteria_cpa_only'),
  [KpiCriteriaEnum.ROAS_ONLY]: i18n.global.t('target.kpi_index_setting.criteria_roas_only'),
  [KpiCriteriaEnum.CV_ONLY]: i18n.global.t('target.kpi_index_setting.criteria_cv_only')
} as const satisfies Record<KpiCriteria, string>
